import Choices from "../../tools/node_modules/choices.js";

export {};


let initApp = () => {
  // Pass single element
  const element = document.querySelector('select.choices-js');
  if (element) {
      // @ts-ignore
      const choices = new Choices(element, {"shouldSort": false});
  }

    if (document.querySelector("#login-form .timeleft")) {
        let countdownNum = 40;

        let incTimer = function() {
            setTimeout(function () {
                if (countdownNum != 0) {
                    countdownNum--;
                    document.querySelector('#login-form .timeleft').innerHTML = countdownNum.toString();
                    incTimer();
                } else {
                    (<HTMLButtonElement>document.querySelector('#login-form .resend-btn')).disabled = null;
                    document.querySelector('#login-form .timeleft').innerHTML = '';
                }
            }, 1000);
        };
        incTimer();
    }



}

initApp();
